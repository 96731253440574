
.visibility-toggle{
  position: absolute;
  top: 15px;
  right: 15px;
  button{
    padding: 2px 10px;
    i{
      display: block;
      font-size: 20px;
      line-height: 15px;
    }
    span{
      display: block;
      font-size: 10px;
    }
  }
}
